import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";
import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import InstagramIcon from "../../assets/images/seoimages/insta.png";
import FacebookIcon from "../../assets/images/seoimages/facebook.png";
import LinkedInIcon from "../../assets/images/seoimages/linkdin.png";
import Whatsapp from "../../assets/images/seoimages/WhatsApp.png";
import { Link } from "react-router-dom";
import { IoSettings } from "react-icons/io5";
const Productblog = () => {
  const APP_ID = "1299515110572739";
  const [searchTerm, setSearchTerm] = useState("");
  const [blogdata, setBlogData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isLoadingShortUrl, setIsLoadingShortUrl] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const handleShowModal = (blog) => {
    setSelectedBlog(blog);
    setShowModal(true);
    Shortlinkurl(blog.post_slug, blog.post_id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBlog(null);
  };

  const Getproductblogs = async () => {
    setLoading(true);
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          product_name: productname,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetProductBlogs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setBlogData(data.data || []);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setTransactionLoaderState(false);
    }
  };

  const generateShareLink = (platform, blog) => {
    const shareUrl = window.location.origin + "/blog/" + blog.post_id;
    if (platform === "facebook") {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl
      )}`;
    } else if (platform === "linkedin") {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        shareUrl
      )}`;
    } else if (platform === "instagram") {
      return "#";
    }
    return "#";
  };

  const shareToFeed = (blog) => {
    console.log(shareToFeed, "sjakhdfg");
    // const shareLink = `https://billpunch.com/Slug?post_slug=${encodeURIComponent(
    //   blog.post_slug
    // )}&post_id=${blog.post_id}`;
    const shareLink = shortUrl;
    window.FB.ui(
      {
        method: "feed",
        link: shareLink,
        caption: blog.post_title,
        description: blog.post_excerpt,
        picture: blog.post_featured_image,
      },
      (response) => {
        console.log("Facebook Share Response:", response);

        if (response && !response.error_message) {
          console.log("Post was shared successfully.");
        } else {
          console.error(
            "Error while sharing the post:",
            response.error_message || "Unknown error"
          );
        }
      }
    );
  };

  const redirectToSlug = (blog) => {
    let baseUrl = "";

    if (blog.product_name === "BillPunch") {
      baseUrl = "https://billpunch.com/blog";
    } else if (blog.product_name === "Anlook") {
      baseUrl = "https://anlook.com/blog";
    } else {
      baseUrl = "https://defaultwebsite.com";
    }

    window.open(`${baseUrl}/${blog.post_slug}`, "_blank");
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: APP_ID,
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });

      window.FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, [APP_ID]);
  useEffect(() => {
    Getproductblogs();
  }, []);

  const lineHeight = "1.5em";
  const maxLines = 3;

  const [shortUrl, setShortUrl] = useState("");
  const Shortlinkurl = async (slug, postId) => {
    setIsLoadingShortUrl(true);
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        url: `https://billpunch.com/Slug?post_slug=${encodeURIComponent(
          slug
        )}&post_id=${postId}`,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log("Sending data to the server...");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/ShortLinkURL`,
        requestOptions
      );
      const data = await res.json();

      if (data.responseCode === "00") {
        setShortUrl(data.data.shortUrl);
        setTransactionLoaderState(false);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoadingShortUrl(false);
      setTransactionLoaderState(false);
    }
  };
  const url = sessionStorage.getItem("selectedURLLabel");
  const productname = sessionStorage.getItem("selectedURLProjectName");
  const filteredBlogs = blogdata.filter((blog) =>
    blog.post_title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div className="m-3">
      <Row>
        <Col className="p-0">
          {" "}
          <h4 className="col">Blogs of {productname}</h4>
        </Col>
        <Col className="justify-content-end d-flex">
          {" "}
          <Form.Control
            type="text"
            placeholder="Search blogs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{width:"40%"}}
          />
        </Col>
      </Row>

      <div
        style={{
          height: "90vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div style={{ display: "none" }}>
          <style>
            {`
        div::-webkit-scrollbar {
          display: none;
        }
      `}
          </style>
        </div>
        <Row>
          {loading ? (
            <div className="text-center " style={{ width: "100%" }}>
              <Spinner animation="border" />
              <p>Loading Product Blogs...</p>
            </div>
          ) : (
            <>
              {filteredBlogs.map((blog) => (
                <Col md={3} key={blog.post_id} className="mb-4">
                  <Card className="p-2" style={{ height: "100%" }}>
                    <Card.Img
                      className="mb-2"
                      variant="top"
                      onClick={() => redirectToSlug(blog)}
                      src={blog.post_featured_image}
                      alt={blog.post_featured_image_alt}
                      style={{
                        height: "180px",
                        objectFit: "cover",
                      }}
                    />
                    <Card.Body
                      className="p-0"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                      }}
                    >
                      {" "}
                      <div
                        onClick={() => redirectToSlug(blog)}
                        style={{ cursor: "pointer" }}
                      >
                        <Card.Title
                          className="mb-2"
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "3em",
                            color: "black",
                          }}
                        >
                          {blog.post_title}
                        </Card.Title>
                        <p
                          className="mb-1"
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: maxLines,
                            lineHeight: lineHeight,
                            maxHeight: `${lineHeight * maxLines}`,
                          }}
                        >
                          {blog.post_excerpt}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <p
                          className="text-muted mt-auto"
                          style={{ marginTop: "auto" }}
                        >
                          <small>
                            By {blog.post_author} on{" "}
                            {new Date(blog.created_at).toLocaleDateString()}
                          </small>
                        </p>

                        <a
                          onClick={() => handleShowModal(blog)}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={FacebookIcon}
                            alt="Facebook"
                            style={{ width: "24px" }}
                          />
                        </a>
                        <a
                          href={generateShareLink("linkedin", blog)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={LinkedInIcon}
                            alt="LinkedIn"
                            style={{ width: "24px" }}
                          />
                        </a>
                        <a
                          href="https://goseo.in/authorize.php"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={InstagramIcon}
                            alt="Instagram"
                            style={{ width: "24px" }}
                          />
                        </a>

                        <a
                          href={generateShareLink("whatsapp", blog)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={Whatsapp}
                            alt="whatsapp"
                            style={{ width: "24px" }}
                          />
                        </a>
                        <Link
                          to="/Go_seo/Blog_Setting"
                          onClick={() => {
                            sessionStorage.setItem(
                              "Blogseodata",
                              JSON.stringify(blog)
                            );
                          }}
                        >
                          <IoSettings
                            style={{ fontSize: "1.2rem", color: "grey" }}
                          />
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Share on Facebook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingShortUrl ? (
            <div className="text-center">
              <Spinner animation="border" />
              <p>Loading your short URL...</p>
            </div>
          ) : (
            <>
              <p>Your short URL:</p>
              <a href={shortUrl} target="_blank" rel="noopener noreferrer">
                {shortUrl}
              </a>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              shareToFeed({ selectedBlog });
              handleCloseModal();
            }}
          >
            Share
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Link to="/Go_seo/FacebookPostTracker">Post track</Link> */}
    </div>
  );
};

export default Productblog;
