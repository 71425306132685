import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Authorsetting,
  Blogaragraph,
  Blogbasicsetting,
  Blogheader,
  Blogkeyword,
  Blogogsetting,
  Blogschema,
} from "./Forms";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";
import swal from "sweetalert";
import { AiOutlineClose } from "react-icons/ai";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
const Blogsetting = () => {
  const storedBlogData = sessionStorage.getItem("Blogseodata");
  const Blogseodata = storedBlogData ? JSON.parse(storedBlogData) : null;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const baseURL = Blogseodata?.product_name
    ? `https://${Blogseodata.product_name}.com`
    : "https://goseo.in/user-pages/login-2";
  const formattedSlugUrl = `${baseURL}/blog/${Blogseodata?.post_slug}`;
  const [postdata, setpostdata] = useState([]);
  const [activeTab, setActiveTab] = useState("Basic");
  const GetPublishedblog = async (slug) => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ p_slug: Blogseodata.post_slug }),
        redirect: "follow",
      };
  
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetPublishedBlogPost`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);
  
      if (data.status === "0") {
        const post = data.data[0];
        setpostdata(post);
        setFormData({
          title: post.post_title || "",
          metaDescription: post.meta_description || "",
          ogTitle: post.og_title || "",
          ogDescription: post.og_description || "",
          ogUrl: post.og_url || "",
          ogImage: post.og_image || "",
          ogType: post.og_type || "",
          twitterCard: post.twitter_card || "",
          twitterTitle: post.twitter_title || "",
          twitterImage: post.twitter_image || "",
  
        });
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching blog:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  
  console.log(postdata);
  useEffect(() => {
    GetPublishedblog();
  }, []);
  const BasicNext = () => {
    setActiveTab("OG");
  };
  const OgNext = () => {
    setActiveTab("Keywords");
  };
  const KeywordNext = () => {
    setActiveTab("Author");
  };
  const [formData, setFormData] = useState({
    title: "",
    metaDescription: "",
    ogTitle: "",
    ogDescription: "",
    ogUrl: "",
    ogImage: "",
    ogType: "",
    twitterCard: "",
    twitterTitle: "",
    twitterDescription: "",
    twitterImage: "",
    Authorname: "",
    Authorurl: "",
    Publishername: "",
    Publisherlogo: "",
    Pageurl: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // keywords

  const id = sessionStorage.getItem("selectedURLId");
  console.log(id);
  const pageid = JSON.parse(sessionStorage.getItem("pageid"));
  console.log(pageid);
  const [keyword, setKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  useEffect(() => {
    if (postdata?.post_keywords) {
      const prefilledKeywords = postdata.post_keywords.split(",").map((kw) => ({
        keyword: kw.trim(),
        id: Date.now() + Math.random(),
      }));
      setKeywords(prefilledKeywords);
    }
  }, [postdata]);
  const handleAddKeyword = (kw = keyword) => {
    if (kw) {
      const newKeywords = kw
        .split(",")
        .map((kw) => kw.trim())
        .filter(
          (kw) => kw && !keywords.some((existing) => existing.keyword === kw)
        );
      setKeywords([
        ...keywords,
        ...newKeywords.map((kw) => ({
          keyword: kw,
          id: Date.now() + Math.random(),
        })),
      ]);
      setKeyword("");
      setSuggestedKeywords([]);
    }
  };

  const handleRemoveKeyword = async (keyid) => {
    console.log(handleRemoveKeyword);
    console.log(keyid);
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          webid: id,
          pageid: Blogseodata.post_id,
          keyid: keyid,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/DeleteKeywords`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.data[0].message, "success");
        setKeywords(keywords.filter((kw) => kw.id !== keyid));
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const Getkeyworddata = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id, pageid: pageid.id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllKeywords`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setKeywords(
          data.data.map((item) => ({
            keyword: item.keyword,
            id: item.id,
          }))
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const url = sessionStorage.getItem("selectedURLLabel");
  const GetSuggestedKeywordData = async (input) => {
    if (input.trim() === "") {
      setSuggestedKeywords([]);
      return;
    }
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userid: user.user_id,
          url: url,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllKeywordsSuggestion`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const allKeywords = data.data[0].keywords.split(",");
        setSuggestedKeywords(allKeywords);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getkeyworddata();
  }, []);

  // Keywords

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedKeywords = keywords.map((kw) => kw.keyword).join(",");
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          post_id: Blogseodata.post_id,
          meta_title: formData.title,
          meta_description: formData.metaDescription,
          og_title: formData.ogTitle,
          og_description: formData.ogDescription,
          og_image: formData.ogImage,
          og_url: formData.ogUrl,
          og_type: formData.ogType,
          twitter_card: formData.twitterCard,
          twitter_title: formData.twitterTitle,
          twitter_image: formData.twitterImage,
          author_name: formData.Authorname,
          author_Url: formData.Authorurl,
          publisher_name: formData.Publishername,
          publisher_logo: formData.Publisherlogo,
          keywords: formattedKeywords,
          page_url: formData.Pageurl,
        }),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/InsertBlogSeo`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const message = data.result[0].message;
        swal("Success", message, "success");
      } else {
        swal("Error", data.msg, "error");
      }
      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className="m-3">
      <div className="row">
        <div className="col">
          <span>
            {" "}
            <h4> Seo Setting </h4>
          </span>
        </div>

        <div className="col d-flex justify-content-end">
          <Link to="/Go_seo/Productblog">
            <Button size="sm" variant="info" style={{ height: "2rem" }}>
              Back
            </Button>
          </Link>
        </div>
      </div>
      <div className="row pb-0">
        <h6 className="col-2">
          <strong>Title : </strong>
        </h6>
        <p className="col">{Blogseodata.post_title}</p>
      </div>
      <div className="row pb-0 pt-0">
        <h6 className="col-2">
          <strong>Url: </strong>
        </h6>
        <p className="col">
          {" "}
          <a href={formattedSlugUrl} target="_blank" rel="noopener noreferrer">
            {formattedSlugUrl}
          </a>
        </p>
      </div>
      <div>
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="d-flex"
          id="seo-setting-tabs"
          style={{ justifyContent: "space-around" }}
        >
          <Tab
            eventKey="Basic"
            title={<span style={{ fontSize: ".9rem" }}>Basic Settings</span>}
            className="mx-2"
          >
            <div className="p-3">
              <p style={{ fontSize: "1rem" }}>Basic Settings</p>
              <Blogbasicsetting
                postdata={postdata}
                BasicNext={BasicNext}
                formData={formData}
                handleChange={handleChange}
              />
            </div>
          </Tab>

          <Tab
            className="mx-2"
            eventKey="OG"
            title={<span style={{ fontSize: ".9rem" }}>OG Settings</span>}
          >
            <div className="p-3">
              <p style={{ fontSize: "1rem" }}>OG Settings</p>
              <Blogogsetting
                postdata={postdata}
                formData={formData}
                handleChange={handleChange}
                OgNext={OgNext}
              />
            </div>
          </Tab>
          <Tab
            className="mx-2"
            eventKey="Keywords"
            title={<span style={{ fontSize: ".9rem" }}>Keywords Settings</span>}
          >
            <div className="p-3">
              <p className="mb-2" style={{ fontSize: "1rem" }}>
                Add Keywords{" "}
              </p>
              <>
                <Form inline className="">
                  <Form.Control
                    type="text"
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                      GetSuggestedKeywordData(e.target.value);
                    }}
                    placeholder="Enter keywords separated by commas"
                    className="mr-2"
                    style={{ width: "50%" }}
                  />
                  <IoCheckmarkDoneCircle
                    style={{
                      color: "green",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => handleAddKeyword()}
                  />
                </Form>
                {suggestedKeywords.length > 0 && (
                  <div
                    className="suggestion-dropdown"
                    style={{
                      maxHeight: "150px",
                      overflowY: "auto",
                      border: "1px solid #ddd",
                      marginTop: "10px",
                    }}
                  >
                    {suggestedKeywords.map((suggestion, index) => (
                      <div
                        key={index}
                        className="suggestion-item"
                        style={{
                          cursor: "pointer",
                          padding: "5px",
                          borderBottom: "1px solid #ddd",
                        }}
                        onClick={() => handleAddKeyword(suggestion)}
                      >
                        {suggestion}
                      </div>
                    ))}
                  </div>
                )}
                <Tab.Container id="keyword-tabs" defaultActiveKey="keywords">
                  <Tab.Content>
                    <Tab.Pane eventKey="keywords">
                      <div className="d-flex flex-wrap">
                        {keywords.length > 0 ? (
                          <>
                            {keywords.map((kw) => (
                              <div
                                key={kw.id}
                                className="d-flex align-items-center m-1 p-2 border rounded bg-light"
                                style={{
                                  position: "relative",
                                  margin: "5px",
                                  padding: "5px 10px",
                                }}
                              >
                                {kw.keyword} &nbsp; &nbsp;
                                <AiOutlineClose
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleRemoveKeyword(kw.id)}
                                />
                              </div>
                            ))}
                            <Button
                              type="button"
                              size="sm"
                              variant="primary"
                              className="ml-2 mt-2"
                              style={{ height: "2rem" }}
                              onClick={() => {
                                KeywordNext();
                              }}
                            >
                              Save & Next
                            </Button>
                          </>
                        ) : (
                          <div className="text-center mt-4">
                            No keywords found
                          </div>
                        )}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </>
            </div>
          </Tab>
          <Tab
            eventKey="Author"
            title={<span style={{ fontSize: ".9rem" }}>Author Settings</span>}
            className="mx-2"
          >
            <div className="p-3">
              <p style={{ fontSize: "1rem" }}>Author Settings</p>
              <Authorsetting
                postdata={postdata}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Blogsetting;
