import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import Select from "react-select";
import swal from "sweetalert";

const Access = () => {
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [projects, setProjects] = useState([]);
  const { setTransactionLoaderState } = useContext(GlobalContext);

  const Getuserdata = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetAllSeoUser`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        const options = data.data.map((user) => ({
          value: user.id,
          label: user.user_name,
        }));
        setUserOptions(options);
      } else {
        console.error("Failed to fetch users:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const GenerateUserAccessManagement = async (userid) => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ userid }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GenerateUserAccessManagement`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setProjects(data.result || []);
      } else {
        console.error("Failed to fetch project data:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUserId(selectedOption ? selectedOption.value : null);
    if (selectedOption) {
      GenerateUserAccessManagement(selectedOption.value);
    }
  };

  const handleToggle = async (projectId, currentStatus) => {
    const newStatus = currentStatus === "Y" ? "N" : "Y"; 
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        id: projectId,
        userid: selectedUserId, 
        status: newStatus,
      }),
      redirect: "follow",
    };

    try {
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/UpdateUserAccessManagement`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        // Show success message with SweetAlert
        swal({
          title: "Success",
          text: data.result[0].message, // Use the message from the response
          icon: "success",
          button: "OK",
        });
  
        // Update local state with the new status
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project.project_id === projectId
              ? { ...project, status: newStatus }
              : project
          )
        );
      } else {
        console.error("Failed to update project data:", data.msg);
        // Show error message if update fails
        swal({
          title: "Error",
          text: data.msg || "Failed to update project data.",
          icon: "error",
          button: "OK",
        });
      }
    } catch (error) {
      console.log("error", error);
      // Show error message for catch block
      swal({
        title: "Error",
        text: "An unexpected error occurred.",
        icon: "error",
        button: "OK",
      });
    }
  };
  useEffect(() => {
    Getuserdata();
  }, []);

  const customStyles = {
    container: (provided) => ({ ...provided, width: "25%" }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "white",
      color: "black",
    }),
    singleValue: (provided) => ({ ...provided, color: "black" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#f0f0f0" : "white",
      color: "black",
    }),
  };

  return (
    <div className="m-3">
      <h4 className="px-2">Access Management</h4>
      <div
        style={{
          height: "90vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div style={{ display: "none" }}>
          <style>
            {`
        div::-webkit-scrollbar {
          display: none;
        }
      `}
          </style>
        </div>
        <Select
          options={userOptions}
          placeholder="Select a User"
          isClearable
          onChange={handleUserChange}
          styles={customStyles}
        />
        <div style={{ marginTop: "20px" }}>
          {projects.map((project) => (
            <div
              key={project.project_id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                borderBottom: "1px solid #ddd",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1, padding: "0 10px" }}>
                <span>{project.project_name}</span>
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "0 10px",
                  fontSize: "12px",
                  color: "#555",
                }}
              >
                <span>{project.site_url}</span>
              </div>

              <div
                onClick={() => handleToggle(project.project_id, project.status)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  width: "50px",
                  height: "24px",
                  borderRadius: "50px",
                  backgroundColor: project.status === "Y" ? "#4CAF50" : "#f44336",
                  position: "relative",
                  transition: "background-color 0.3s",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "2px",
                    left: project.status === "Y" ? "26px" : "2px",
                    transition: "left 0.3s",
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Access;
