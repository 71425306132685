import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { SiTicktick } from "react-icons/si";
import { IoMdTimer } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdAdsClick } from "react-icons/md";
import { PiCurrencyDollarFill } from "react-icons/pi";
import {
  FaCarSide,
  FaChartLine,
  FaEye,
  FaFlagCheckered,
  FaLink,
  FaTrophy,
} from "react-icons/fa";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";
const Campaigndashboard = () => {
  const [campaignData, setCampaignData] = useState(null);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [dashboardV2Data, setdashboardv2data] = useState([]);
  const [dashboardV1Data, setdashboardv1data] = useState([]);
  const [dashboardV3Data, setdashboardv3data] = useState([]);
  const [dashboardV5Data, setdashboardv5data] = useState([]);
  const [dashboardV4Data, setdashboardv4data] = useState([]);
  const [dashboardV6Data, setdashboardv6data] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [Getuserdata, setGetuserdata] = useState([]);
  const productname = sessionStorage.getItem("selectedURLProjectName");

  const GetCampaignDashboardV2 = async (campaignId, utmSource) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ campaignId, utm_source: utmSource }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetCampaignDashboardV2`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setdashboardv2data(data.data[0]);
      } else {
        setdashboardv2data([]);
      }
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const GetCampaignDashboardV1 = async (campaignId, utmSource) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ campaignId, utm_source: utmSource }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetCampaignDashboardV1`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setdashboardv1data(data.data[0]);
      } else {
        setdashboardv1data([]);
      }
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const GetCampaignDashboardV3 = async (campaignId, utmSource) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ campaignId, utm_source: utmSource }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetCampaignDashboardV3`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setdashboardv3data(data.data);
      } else {
        setdashboardv3data([]);
      }
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const GetCampaignDashboardV5 = async (campaignId, utmSource) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ campaignId, utm_source: utmSource }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetCampaignDashboardV5`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setdashboardv5data(data.data[0]);
      } else {
        setdashboardv5data([]);
      }
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const GetCampaignDashboardV4 = async (campaignId, utmSource) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ campaignId, utm_source: utmSource }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetCampaignDashboardV4`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setdashboardv4data(data.data);
      } else {
        setdashboardv4data([]);
      }
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const GetUserActiveStatus = async (campaignid, utmSource) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          campaignid,
          utm_source: utmSource,
          product_name: productname,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetUserActiveStatus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setGetuserdata(data.data);
      } else {
        setGetuserdata([]);
      }
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  const GetCampaignDashboardV6 = async (campaignId) => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          p_campaign_id: campaignId,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetLoggingSummaryReportV2`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const sortedData = data.data.sort(
          (a, b) => a.step_order - b.step_order
        );
        setdashboardv6data(sortedData);
        console.log(sortedData); 
      } else {
        setdashboardv6data([]);
      }
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    const storedCampaign = sessionStorage.getItem("selectedCampaign");
    console.log(storedCampaign);
    if (storedCampaign) {
      const campaign = JSON.parse(storedCampaign);
      setCampaignData(campaign);
      GetCampaignDashboardV2(campaign.campaign_id, campaign.utm_source);
      GetCampaignDashboardV1(campaign.campaign_id, campaign.utm_source);
      GetCampaignDashboardV3(campaign.campaign_id, campaign.utm_source);
      GetCampaignDashboardV4(campaign.campaign_id, campaign.utm_source);
      GetCampaignDashboardV5(campaign.campaign_id, campaign.utm_source);
      GetCampaignDashboardV6(campaign.campaign_id);
      GetUserActiveStatus(campaign.campaign_id, campaign.utm_source);
    }
  }, []);
  const cardData = [
    {
      icon: <MdAdsClick style={{ color: "#FF5733", fontSize: "1.3rem" }} />,
      title: "Source",
      value: campaignData?.utm_source || "N/A",
    },
    {
      icon: (
        <PiCurrencyDollarFill
          style={{ color: "#28A745", fontSize: "1.3rem" }}
        />
      ),
      title: "Medium",
      value: campaignData?.utm_medium || "N/A",
    },
    {
      icon: <FaLink style={{ color: "#007BFF", fontSize: "1.3rem" }} />,
      title: "Campaign",
      value: campaignData?.utm_campaign || "N/A",
    },
    {
      icon: <IoMdTimer style={{ color: "#FFC107", fontSize: "1.3rem" }} />,
      title: "Term",
      value: campaignData?.utm_term || "N/A",
    },
    {
      icon: <SiTicktick style={{ color: "#6F42C1", fontSize: "1.3rem" }} />,
      title: "Content",
      value: campaignData?.utm_content || "N/A",
    },
  ];
  const Result = [
    {
      icon: <FaEye style={{ color: "#007BFF", fontSize: "1.3rem" }} />,
      title: "Total Visit",
      value: dashboardV1Data ? dashboardV1Data.total_visits : "N/A",
    },
    {
      icon: <FaChartLine style={{ color: "#FFC107", fontSize: "1.3rem" }} />,
      title: "Total Session",
      value: dashboardV2Data ? dashboardV2Data.total_sessions : "N/A",
    },
    {
      icon: <FaEye style={{ color: "#007BFF", fontSize: "1.3rem" }} />,
      title: "Total Time",
      value: dashboardV5Data.total_engagement_time_in_seconds
        ? (
            parseInt(dashboardV5Data.total_engagement_time_in_seconds, 10) / 60
          ).toFixed(2) + " min"
        : "NA",
    },
    {
      icon: <FaTrophy style={{ color: "#28A745", fontSize: "1.3rem" }} />,
      title: "Avg. Time",
      value: dashboardV5Data.avg_engagement_time_in_seconds
        ? (
            parseInt(dashboardV5Data.avg_engagement_time_in_seconds, 10) / 60
          ).toFixed(2) + " min"
        : "NA",
    },
  ];
  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const doughnutData1 = {
    datasets: [
      {
        data: [
          dashboardV2Data?.success_rate || 0,
          dashboardV2Data?.bounce_rate || 0,
        ],
        backgroundColor: ["#FFC107", "#9E9E9E"],
        hoverBackgroundColor: ["#FFD54F", "#BDBDBD"],
      },
    ],
    labels: ["Success Rate", "Bounce Rate"],
  };
  const labelsMap = {
    desktop_user: "Desktop Users",
    mobile_user: "Mobile Users",
    tablet_user: "Tablet Users",
  };
  const employeeCounts = {
    desktop_user: parseInt(dashboardV1Data.desktop_user || 0),
    mobile_user: parseInt(dashboardV1Data.mobile_user || 0),
    tablet_user: parseInt(dashboardV1Data.tablet_user || 0),
  };
  const chartLabels = Object.keys(employeeCounts).map((key) => labelsMap[key]);
  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        data: Object.values(employeeCounts),
        backgroundColor: ["#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
  };

  const totalCount = dashboardV3Data.reduce(
    (sum, item) => sum + parseInt(item.state_count, 10),
    0
  );

  const chartData1 = {
    labels: dashboardV4Data.map((item) => item.button_name),
    datasets: [
      {
        label: "User Count",
        data: dashboardV4Data.map((item) => Number(item.user_count)),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };
  const totalUsers =
    parseInt(dashboardV1Data?.direct_user || 0) +
    parseInt(dashboardV1Data?.referral_user || 0);
    const totalSteps = dashboardV6Data.findIndex((step) => step.final_step === "1") + 1 || dashboardV6Data.length;

    console.log(totalSteps); 
  return (
    <>
      <div className="m-3">
        <div className="row">
          <div className="col">
            <h5>Dashboard of {campaignData?.utm_campaign}</h5>
          </div>
          <div className="col mr-3 d-flex justify-content-end">
            <Link to="/Go_seo/Campaign">
              <Button variant="info" size="sm" className="ml-2">
                Back
              </Button>
            </Link>
          </div>
        </div>
        <Row className="mx-1">
          <Col md={2} className="d-flex align-items-center p-1">
            {" "}
            <h6 style={{ color: "green" }}>Victory Campaign</h6>
          </Col>
          <Col md={9}>
            <div
              style={{
                position: "relative",
                margin: "20px 0",
                height: "50px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "0",
                  right: "0",
                  height: "4px",
                  backgroundColor: "#d3d3d3",
                  transform: "translateY(-50%)",
                }}
              ></div>

              {dashboardV6Data.slice(0, totalSteps).map((step, index) => (
                <OverlayTrigger
                  key={step.id}
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${step.id}`}>
                      <div>{step.step_name}</div>
                      <div>Visit count: {step.user_count}</div>
                    </Tooltip>
                  }
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: `${(index / (totalSteps - 1)) * 100}%`,
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor:
                          step.step_order === currentStep ? "#FFD700" : "#000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {step.final_step === "1" && index === totalSteps - 1 && (
                        <img
                          src={require("../../assets/images/seoimages/victory.gif")}
                          alt="Victory"
                          style={{
                            position: "absolute",
                            left: "100%",
                            width: "5vw",
                          }}
                        />
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        color: "#000",
                        width: "3rem",
                        textAlign: "center",
                      }}
                    >
                      Step {step.step_order}
                    </div>
                  </div>
                </OverlayTrigger>
              ))}

              <FaFlagCheckered
                style={{
                  position: "absolute",
                  top: "50%",
                  left: `${((currentStep - 1) / (totalSteps - 1)) * 100}%`,
                  transform: "translate(-50%, -150%)",
                  fontSize: "2rem",
                  transition: "left 0.5s ease",
                  color: "red",
                }}
              />
            </div>
          </Col>
        </Row>
        <div className="row pt-0">
          <div className="col-md-8 col-12">
            {/* success bounce & pie */}
            <Row className="pt-0 pb-0">
              <Col md={6} className="mb-2 p-1">
                <Card className="h-100 p-0">
                  <Card.Body className="d-flex align-items-center justify-content-between p-2">
                    {dashboardV2Data &&
                    Object.keys(dashboardV2Data).length > 0 ? (
                      <div style={{ width: "50%", height: "200px" }}>
                        <Doughnut
                          data={doughnutData1}
                          options={doughnutOptions}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <p>No data found</p>
                      </div>
                    )}
                    <div style={{ marginLeft: "16px", textAlign: "left" }}>
                      <div style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "500" }}>Success: </span>
                        {dashboardV2Data.success_rate || 0}%
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "500" }}>Bounce: </span>
                        {dashboardV2Data.bounce_rate || 0}%
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6} className="mb-2 p-1">
                <Card className="h-100 p-0">
                  <h6 className="text-center mt-2">Visitors Tracking</h6>
                  <Card.Body className="d-flex align-items-center justify-content-between p-2">
                    <div style={{ width: "50%", height: "200px" }}>
                      {dashboardV1Data &&
                      Object.keys(dashboardV1Data).length > 0 ? (
                        <Pie data={chartData} options={options} />
                      ) : (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <p>No data found</p>
                        </div>
                      )}
                    </div>
                    <div>
                      <div style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "500" }}>
                          Desktop Users:{" "}
                        </span>
                        {employeeCounts.desktop_user}
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "500" }}>
                          Mobile Users:{" "}
                        </span>
                        {employeeCounts.mobile_user}
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <span style={{ fontWeight: "500" }}>
                          Tablet Users:{" "}
                        </span>
                        {employeeCounts.tablet_user}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="pt-0 pb-0">
              <Col md={6} className="mb-2 p-1">
                <div className="card p-2">
                  {dashboardV1Data && totalUsers > 0 ? (
                    <>
                      <div className="d-flex justify-content-between mb-3">
                        <span style={{ fontWeight: "bold", fontSize: ".8rem" }}>
                          Source
                        </span>
                        <span style={{ fontWeight: "bold", fontSize: ".8rem" }}>
                          Visitor
                        </span>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <span style={{ fontSize: ".8rem" }}>
                            Direct Visitor
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {dashboardV1Data?.direct_user}
                          </span>
                        </div>
                        <ProgressBar
                          now={
                            totalUsers > 0
                              ? (parseInt(dashboardV1Data?.direct_user) /
                                  totalUsers) *
                                100
                              : 0
                          }
                          label={`${(
                            (parseInt(dashboardV1Data?.direct_user) /
                              totalUsers) *
                            100
                          ).toFixed(2)}%`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${
                                (parseInt(dashboardV1Data?.direct_user) /
                                  totalUsers) *
                                100
                              }%`,
                              backgroundColor: "#FFD700",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <span style={{ fontSize: ".8rem" }}>
                            Referral Visitor
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {dashboardV1Data?.referral_user}
                          </span>
                        </div>
                        <ProgressBar
                          now={
                            totalUsers > 0
                              ? (parseInt(dashboardV1Data?.referral_user) /
                                  totalUsers) *
                                100
                              : 0
                          }
                          label={`${(
                            (parseInt(dashboardV1Data?.referral_user) /
                              totalUsers) *
                            100
                          ).toFixed(2)}%`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${
                                (parseInt(dashboardV1Data?.referral_user) /
                                  totalUsers) *
                                100
                              }%`,
                              backgroundColor: "#FFD700",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <p>No data found</p>
                    </div>
                  )}
                </div>
              </Col>

              <Col md={6} className="mb-2 p-1">
                <div className="card p-2 ">
                  {Getuserdata?.[0] ? (
                    <>
                      <div className="d-flex justify-content-between mb-3">
                        <span style={{ fontWeight: "bold", fontSize: ".8rem" }}>
                          Timeframe
                        </span>
                        <span style={{ fontWeight: "bold", fontSize: ".8rem" }}>
                          Active Visitor
                        </span>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <span style={{ fontSize: ".8rem" }}>
                            {" "}
                            Last 30 Minutes
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {Getuserdata[0].users_last_30min}
                          </span>
                        </div>
                        <ProgressBar
                          now={
                            Getuserdata[0].users_last_30min
                              ? (Getuserdata[0].users_last_30min / totalUsers) *
                                100
                              : 0
                          }
                          label={`${(
                            (Getuserdata[0].users_last_30min / totalUsers) *
                            100
                          ).toFixed(2)}%`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${
                                (Getuserdata[0].users_last_30min / totalUsers) *
                                100
                              }%`,
                              backgroundColor: "#FFD700",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <span style={{ fontSize: ".8rem" }}>
                            Last 5 Minutes
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {Getuserdata[0].users_last_5min}
                          </span>
                        </div>
                        <ProgressBar
                          now={
                            Getuserdata[0].users_last_5min
                              ? (Getuserdata[0].users_last_5min / totalUsers) *
                                100
                              : 0
                          }
                          label={`${(
                            (Getuserdata[0].users_last_5min / totalUsers) *
                            100
                          ).toFixed(2)}%`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${
                                (Getuserdata[0].users_last_5min / totalUsers) *
                                100
                              }%`,
                              backgroundColor: "#FFD700",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <p>No data found</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="col-md-4 p-0 col-12">
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.125)",
                borderRadius: ".3rem",
              }}
            >
              {cardData.map((data, index) => (
                <div key={index} md={2} className="">
                  <div className="row ">
                    <Card.Text
                      className="col-5  mb-2"
                      style={{ color: "black", fontSize: "1rem" }}
                    >
                      {data.icon}{" "}
                      <span style={{ marginLeft: "8px" }}>{data.title}</span>
                    </Card.Text>

                    <Card.Text
                      className="col-6 p-0"
                      style={{ fontSize: "1rem" }}
                    >
                      {data.value}
                    </Card.Text>
                  </div>
                </div>
              ))}
            </div>

            <div
              className="mt-3"
              style={{
                border: "1px solid rgba(0, 0, 0, 0.125)",
                borderRadius: ".3rem",
              }}
            >
              {Result.map((data, index) => (
                <div key={index} md={2} className="">
                  <div className="row ">
                    <Card.Text
                      className="col-6 mb-2"
                      style={{ color: "black", fontSize: "1rem" }}
                    >
                      {data.icon}{" "}
                      <span style={{ marginLeft: "8px" }}>{data.title}</span>
                    </Card.Text>

                    <Card.Text className="col-6 " style={{ fontSize: "1rem" }}>
                      {data.value}
                    </Card.Text>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Row className="pt-0 pb-0">
          <Col md={5} className="mb-4">
            <Card className="p-0" style={{ height: "35vh" }}>
              <Card.Body
                className="p-2"
                style={{ overflowY: "auto", maxHeight: "100%" }}
              >
                {dashboardV3Data && dashboardV3Data.length > 0 ? (
                  dashboardV3Data.map((item, index) => {
                    const stateCount = parseInt(item.state_count, 10);
                    const percentage =
                      totalCount > 0 ? (stateCount / totalCount) * 100 : 0;

                    return (
                      <div className="mt-2" key={index}>
                        <strong className="mb-1">{item.state}</strong>:{" "}
                        {stateCount}
                        <ProgressBar
                          variant="info"
                          now={percentage}
                          label={stateCount.toString()}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <p>No data found</p>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col md={7} className="mb-4 " style={{ height: "38vh" }}>
            {dashboardV4Data && dashboardV4Data.length > 0 ? (
              <Bar
                data={chartData1}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1,
                      },
                    },
                  },
                }}
                height={255}
              />
            ) : (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <p>No data found</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Campaigndashboard;
