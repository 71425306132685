import React from "react";
import './Facebookcards.css'; 
import { FaEye, FaShare } from "react-icons/fa";
const Facebookcards = () => {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-4">
          <div className="card p-0 position-relative overflow-hidden">
            <img
              className="card-img-top"
              src={require("../../assets/images/seoimages/Blogo.png")}
              alt="Facebook Icon"
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
            <div className="card-body">
              <h5 className="card-title" style={{ color: "black" }}>
                Card Title 4
              </h5>
            </div>
            <div className="gradient-overlay"></div> 
            <div className="icon-overlay">
              <FaShare />
              <FaEye  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facebookcards;
