import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import Select from "react-select";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Button, Card, Modal, Nav, Spinner, Tab, Table } from "react-bootstrap";
import { FaShare } from "react-icons/fa";
import Swal from "sweetalert2";
import swal from "sweetalert";
import Map from "./Map";
import { ProgressBar } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { Link } from "react-router-dom";
import LineChart from "./Speedometer";
import { Jsonmodal, Projectmodal } from "./Modal";
import { Campaigndashboard } from "./Forms";
const Dashboards = () => {
  const { user } = useContext(GlobalContext);
 
  const [selectedURL, setSelectedURL] = useState({});
  const [siteData, setSiteData] = useState({
    direct_users: 0,
    refreal_users: 0,
  });
  const [urlOptions, setUrlOptions] = useState([]);
  const [seodata, setseodata] = useState([]);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [showModal, setShowModal] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [userCountData, setUserCountData] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const url = sessionStorage.getItem("selectedURLLabel");
  const id = sessionStorage.getItem("selectedURLId");
  const handleShowModal = () => {
    setShowModal(true);
    Getseosetting();
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setJsonData(null);
  };
  const handleSelectChange = (selectedOption) => {
    setSelectedURL(selectedOption);
    sessionStorage.setItem("selectedURL", selectedOption.value);
    sessionStorage.setItem("selectedURLId", selectedOption.id);
    sessionStorage.setItem("selectedURLLabel", selectedOption.label);
    sessionStorage.setItem(
      "selectedURLProjectName",
      selectedOption.project_name
    );
    console.log("Selected Option:", selectedOption.project_name);
    setModalIsOpen(false);
  
    setIsURLSelected(true);
  };

  const Getsitemapurl = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userid: user.user_id }),
      };
  
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GenerateUserAccessManagement`,
        requestOptions
      );
      const data = await res.json();
  
      console.log("API Response:", data);
  
      if (data.status === "0") {
        const options = data.result.map((item) => ({
          value: item.site_url,
          label: `${item.site_url} `, 
          id: item.project_id,
          project_name: item.project_name,
        }));
        setUrlOptions(options);
      } else {
        console.log("Error fetching URLs:", data.msg);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  
  useEffect(() => {
    const modalShown = sessionStorage.getItem("modalShown");
    console.log("Checking if modal has been shown...");

    if (!modalShown) {
      console.log("Modal has not been shown. Opening modal...");
      Getsitemapurl().then(() => {
        setModalIsOpen(true);
        sessionStorage.setItem("modalShown", "true");
        console.log("Modal opened successfully.");
      });
    }
  }, []);

  const Getseosetting = async () => {
    setTransactionLoaderState(true);
    try {
      if (!selectedURL) {
        console.warn("No URL selected");
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: id }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetSeoSettings`,
        requestOptions
      );
      const data = await res.json();

      setJsonData(data);
      if (data.status === "0") {
        setseodata(data.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    Getsitemapurl();
    if (id) {
      Getseosetting();
    }
  }, [selectedURL]);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "14rem",
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: ".4rem",
      width: "14rem",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#f0f0f0" : "#fff",
      color: state.isSelected ? "#000" : "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#999",
    }),
  };

  const validateJson = () => {
    if (jsonData) {
      try {
        JSON.parse(JSON.stringify(jsonData));
        Swal.fire({
          icon: "success",
          title: '<span style="color: black;">JSON is valid!</span>',
          html: '<span style="color: black;">The JSON data is correctly formatted.</span>',
          didOpen: () => {
            const titleElement = Swal.getTitle();
            const htmlContainer = Swal.getHtmlContainer();
            if (titleElement) titleElement.style.color = "black";
            if (htmlContainer) htmlContainer.style.color = "black";
          },
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: '<span style="color: black;">Invalid JSON</span>',
          html: '<span style="color: black;">The JSON data is not properly formatted.</span>',
          didOpen: () => {
            const titleElement = Swal.getTitle();
            const htmlContainer = Swal.getHtmlContainer();
            if (titleElement) titleElement.style.color = "black";
            if (htmlContainer) htmlContainer.style.color = "black";
          },
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: '<span style="color: black;">No Data</span>',
        html: '<span style="color: black;">No JSON data available to validate.</span>',
        didOpen: () => {
          const titleElement = Swal.getTitle();
          const htmlContainer = Swal.getHtmlContainer();
          if (titleElement) titleElement.style.color = "black";
          if (htmlContainer) htmlContainer.style.color = "black";
        },
      });
    }
  };
  const handleSubmit = async () => {
    if (!selectedURL) {
      swal({
        icon: "warning",
        title: "No URL Selected",
        text: "Please select a URL before submitting.",
      });
      return;
    }

    const urlWithoutWWW = selectedURL.label.replace(
      /^https?:\/\/www\./,
      "https://"
    );
    const apiURL = `${urlWithoutWWW}update_manifest.php`;

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ webid: selectedURL.id }),
      };

      const res = await fetch(apiURL, requestOptions);
      const data = await res.json();

      if (data.status === "0") {
        swal({
          icon: "success",
          title: "Success",
          text: "Manifest updated successfully!",
        });
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: data.msg || "Something went wrong!",
        });
      }
    } catch (error) {
      console.log("Error:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating the manifest.",
      });
    }
  };

  const handleClose = () => {
    if (isURLSelected) {
      setModalIsOpen(false);
    } else {
      swal({
        icon: "warning",
        title: "Selection Required",
        text: "Please select a URL before closing the modal.",
      });
    }
  };

  const [isURLSelected, setIsURLSelected] = useState(false);

  useEffect(() => {
    const storedURL = sessionStorage.getItem("selectedURL");
    setSelectedURL({
      label: storedURL || "Select Url",
      value: storedURL || "Select Url",
    });
  }, []);

  const [mapData, setMapData] = useState([]);
  const projectName = sessionStorage.getItem("selectedURLProjectName");
    
  const fetchMapData = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ product_name: projectName }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetProductLocation`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        const formattedData = data.data.map((item) => ({
          name: item.product,
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        }));
        setMapData(formattedData);
      } else {
        console.error("Failed to fetch map data:", data.msg);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching map data:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetSiteTrafficDetails = async () => {
    setTransactionLoaderState(true);
    try {
      const projectName = sessionStorage.getItem("selectedURLProjectName");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ product_name: projectName }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetSiteTrafficDetails`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data && data.data[0]) {
        setSiteData(data.data[0]);
      } else {
        console.error("Failed to fetch map data:", data.msg);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching map data:", error);
    }
  };
  const totalUsers =
    parseInt(siteData.direct_users) + parseInt(siteData.refreal_users);
  const Getusercount = async () => {
    setTransactionLoaderState(true);
    try {
      const projectName = sessionStorage.getItem("selectedURLProjectName");

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ productname: projectName }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetCountryCount`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setUserCountData(data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const totalCount = userCountData.reduce(
    (sum, item) => sum + parseInt(item.country_count),
    0
  );

  const [seoData, setSeoData] = useState(null);
  const formatUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const fetchData = async () => {
    const formattedUrl = formatUrl(url);

    const response = await fetch(
      `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetSeoScore`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: formattedUrl }),
      }
    );
    const data = await response.json();
    setSeoData(data);
  };

  useEffect(() => {
    if (url) {
      fetchData();
    }
  }, [selectedURL]);
  
  useEffect(() => {
    if (projectName) {
      fetchMapData();
    }
  }, [selectedURL]);
  useEffect(() => {
    if (projectName) {
      Getusercount();
    }
  }, [selectedURL]);
  useEffect(() => {
    if (projectName) {
      GetSiteTrafficDetails();
    }
  }, [selectedURL]);

  // if (!seoData) {
  //   console.log(seoData);
  //   return (
  //     <div
  //       className="d-flex justify-content-center align-items-center"
  //       style={{ height: "40vh" }}
  //     >
  //       <div className="text-center " style={{ width: "100%" }}>
  //         <Spinner animation="border" />
  //         <p> Loading Dashboard Data...</p>
  //       </div>
  //     </div>
  //   );
  // }
  if (false) {
    console.log(seoData);
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "40vh" }}
      >
        <div className="text-center " style={{ width: "100%" }}>
          <Spinner animation="border" />
          <p> Loading Dashboard Data...</p>
        </div>
      </div>
    );
  }
  
  const pieData = [
    ["Task", "Hours per Day"],
    ["Score", seoData?.score],
    ["Remaining", 100 - seoData?.score],
  ];

  const pieOptions = {
    is3D: true,
    legend: { position: "none" },
    slices: {
      0: { offset: 0, color: "#FFCE56" },
      1: { offset: 0, color: "#C0C0C0" },
    },
  };

  const totalTests = seoData?.totalTests;
  const passedTests = seoData?.passedTests?.length;
  const failedTests = seoData?.failedTests?.length;
  const warningTests = totalTests - passedTests - failedTests;

  const percentagePassed = (passedTests / totalTests) * 100;
  const percentageFailed = (failedTests / totalTests) * 100;
  const percentageWarning = (warningTests / totalTests) * 100;

  return (
    <>
    
      <Tab.Container defaultActiveKey="dashboard1">
        <div className="tab-buttons mt-2">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                className="p-3"
                eventKey="dashboard1"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                Visitor Dashboard
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="p-3"
                eventKey="dashboard2"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                Analytics Dashboard
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link
                className="p-3"
                eventKey="dashboard3"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                Campaign Dashboard
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="dashboard1">
            {" "}
            <div className="m-3 bg-grey-theme">
              <span>
                {" "}
                {selectedURL && <h4> Selected Url: {selectedURL.label}</h4>}
              </span>
              <div className="row">
                <div className="col d-flex align-items-center">
                  <span style={{ color: "#0F67B1" }}>
                    Select Project : &nbsp;
                  </span>
                  <Select
                    options={urlOptions}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select URL"
                    value={selectedURL}
                    onChange={handleSelectChange}
                    styles={customStyles}
                  />
                </div>
                <div className="col d-flex justify-content-end">
                  <div>
                    <Button
                      className="mr-2"
                      variant="primary"
                      size="sm"
                      onClick={handleShowModal}
                    >
                      JSON
                    </Button>
                    <Button variant="secondary" size="sm">
                      <FaShare /> &nbsp; Share
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card p-2">
                <div className="row p-0">
                  <div className="col-md-5 col-12">
                    <Map data={mapData} style={{}} />
                  </div>
                  <div
                    className="col-md-2 card p-2 col-6 mt-md-4"
                    style={{
                      height: "45vh",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    <div className="d-flex justify-content-between mb-3">
                      <span style={{ fontWeight: "bold", fontSize: ".8rem" }}>
                        Country
                      </span>
                      <span style={{ fontWeight: "bold", fontSize: ".8rem" }}>
                        Visitor
                      </span>
                    </div>
                    {userCountData?.map((item, index) => (
                      <div key={index} className="mb-3">
                        <div className="d-flex justify-content-between">
                          <span style={{ fontSize: ".8rem" }}>
                            {item?.country !== "0" ? item.country : "Unknown"}
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {item?.country_count}
                          </span>
                        </div>
                        <ProgressBar
                          now={
                            (parseInt(item?.country_count) / totalCount) * 100
                          }
                          label={`${(
                            (parseInt(item?.country_count) / totalCount) *
                            100
                          ).toFixed(2)}%`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            height: ".2rem",
                          }}
                        >
                          <div
                            style={{
                              width: `${
                                (parseInt(item?.country_count) / totalCount) *
                                100
                              }%`,
                              backgroundColor: "#FFD700",
                              height: ".2rem",
                            }}
                          />
                        </ProgressBar>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-2 col-6 ">
                    <div className="card p-2 mt-md-4">
                      <div className="d-flex justify-content-between mb-3">
                        <span style={{ fontWeight: "bold", fontSize: ".8rem" }}>
                          Source
                        </span>
                        <span style={{ fontWeight: "bold", fontSize: ".8rem" }}>
                          Visitor
                        </span>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <span style={{ fontSize: ".8rem" }}>
                            Direct Visitor
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {siteData?.direct_users}
                          </span>
                        </div>
                        <ProgressBar
                          now={
                            (parseInt(siteData?.direct_users) / totalUsers) * 100
                          }
                          label={`${(
                            (parseInt(siteData?.direct_users) / totalUsers) *
                            100
                          ).toFixed(2)}%`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            height: ".2rem",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${
                                (parseInt(siteData?.direct_users) / totalUsers) *
                                100
                              }%`,
                              backgroundColor: "#FFD700",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <span style={{ fontSize: ".8rem" }}>
                            Referral Visitor
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {siteData.refreal_users}
                          </span>
                        </div>
                        <ProgressBar
                          now={
                            (parseInt(siteData.refreal_users) / totalUsers) *
                            100
                          }
                          label={`${(
                            (parseInt(siteData.refreal_users) / totalUsers) *
                            100
                          ).toFixed(2)}%`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            height: ".2rem",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${
                                (parseInt(siteData.refreal_users) /
                                  totalUsers) *
                                100
                              }%`,
                              backgroundColor: "#FFD700",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 mt-md-0 mt-2 col-6 d-flex justify-content-center">
                    <div
                      // className="phone-frame"
                      style={{
                        width: "80%",
                        maxWidth: "360px",
                        padding: "10px",
                        borderRadius: "30px",
                        border: "16px solid #333",
                        position: "relative",
                        backgroundColor: "#fff",
                        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                        borderWidth: "1rem 7px 7px",
                      }}
                    >
                      <div
                        className="phone-screen"
                        style={{
                          width: "100%",
                          height: "auto",
                          overflow: "hidden",
                          backgroundColor: "#f0f0f0",
                        }}
                      >
                        <img
                          src={seoData?.mobile_view}
                          alt="Mobile View Snapshot"
                          className="img-fluid"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-0">
                <div className="col-md-4 col">
                  <Link to="/Go_seo/Seo_analyzer">
                    <Card className="p-2 mt-2">
                      <Chart
                        chartType="PieChart"
                        data={pieData}
                        options={pieOptions}
                        width={"100%"}
                        height={"350px"}
                      />
                    </Card>
                  </Link>
                </div>
                <div className="col-md-2 p-0 col-6">
                  <Link to="/Go_seo/Seo_analyzer">
                    <Card className="p-2 mt-2">
                      <Card.Title style={{ color: "black", fontSize: "1rem" }}>
                        Site Health
                      </Card.Title>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                          <span style={{ fontSize: ".8rem" }}>Total Tests</span>
                          <span style={{ fontSize: ".8rem" }}>
                            {totalTests}
                          </span>
                        </div>
                        <ProgressBar
                          now={100}
                          label={`${totalTests}`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            height: ".2rem",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#FFD700",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                          <span style={{ fontSize: ".8rem" }}>
                            Passed Tests
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {passedTests}
                          </span>
                        </div>
                        <ProgressBar
                          now={percentagePassed}
                          label={`${passedTests} (${Math.round(
                            percentagePassed
                          )}%)`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            height: ".2rem",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${percentagePassed}%`,
                              backgroundColor: "#28a745",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                          <span style={{ fontSize: ".8rem" }}>
                            Failed Tests
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {failedTests}
                          </span>
                        </div>
                        <ProgressBar
                          now={percentageFailed}
                          label={`${failedTests} (${Math.round(
                            percentageFailed
                          )}%)`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            height: ".2rem",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${percentageFailed}%`,
                              backgroundColor: "#dc3545", // Danger color fill
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>

                      <div className="mb-3">
                        <div className="d-flex justify-content-between mb-2">
                          <span style={{ fontSize: ".8rem" }}>
                            Warning Tests
                          </span>
                          <span style={{ fontSize: ".8rem" }}>
                            {warningTests}
                          </span>
                        </div>
                        <ProgressBar
                          now={percentageWarning}
                          label={`${warningTests} (${Math.round(
                            percentageWarning
                          )}%)`}
                          style={{
                            backgroundColor: "#d3d3d3",
                            height: ".2rem",
                            marginTop: "0.5rem",
                          }}
                          variant="custom"
                          animated
                        >
                          <div
                            style={{
                              width: `${percentageWarning}%`,
                              backgroundColor: "#ffc107",
                              height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </div>
                    </Card>
                  </Link>
                </div>
                <div className="col-md-6 col-12">
                  <div className="card p-2 mt-2">
                    <LineChart />
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="dashboard2">
            <h2>Dashboard 2 Content</h2>
          </Tab.Pane>
          {/* <Tab.Pane eventKey="dashboard3">
            <Campaigndashboard />
          </Tab.Pane> */}
        </Tab.Content>
      </Tab.Container>

      <Projectmodal
        modalIsOpen={modalIsOpen}
        handleClose={handleClose}
        urlOptions={urlOptions}
        selectedURL={selectedURL}
        handleSelectChange={handleSelectChange}
      />
      <Jsonmodal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        loadingModal={loadingModal}
        selectedURL={selectedURL}
        jsonData={jsonData}
        validateJson={validateJson}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Dashboards;
