import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";

const FacebookPostTracker = () => {
  const APP_ID = '442261364984667'; // Your Facebook App ID
  const [postId, setPostId] = useState(null);
  const [postMetrics, setPostMetrics] = useState({
    likes: 0,
    shares: 0,
    views: 0,
  });
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [accessToken, setAccessToken] = useState(null); // Store access token
  const [userId, setUserId] = useState(null); // Store user ID

  useEffect(() => {
    // Check if FB object is available and then initialize
    const initializeFacebookSDK = () => {
      if (window.FB) {
        console.log('Facebook SDK loaded');
        window.FB.init({
          appId: APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v17.0', 
        });

        window.FB.AppEvents.logPageView();
      } else {
        console.error('Facebook SDK not loaded');
      }
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        initializeFacebookSDK(); // Initialize if already present
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = initializeFacebookSDK; // Initialize after script is loaded
    })(document, 'script', 'facebook-jssdk');
  }, [APP_ID]);

  // Function to handle Facebook login
  const handleFacebookLogin = () => {
    if (window.FB) {
      window.FB.login((response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          const userId = response.authResponse.userID;
          
          // Save access token and user ID to state
          setAccessToken(accessToken);
          setUserId(userId);

          // Log access token and userId
          console.log('Access Token:', accessToken);
          console.log('User ID:', userId);
        } else {
          console.error('User cancelled login or did not fully authorize.');
        }
      }, { scope: 'public_profile,email' }); // Specify the permissions you need
    } else {
      console.error('Facebook SDK not initialized');
    }
  };

  const shareToFeed = () => {
    if (window.FB) {
      window.FB.ui({
        method: 'feed',
        link: 'https://yourwebsite.com/your-article', // Replace with the link you want to share
        caption: 'Check out this awesome article!',
        description: 'Here is the description of the content being shared.',
        picture: 'https://yourwebsite.com/path-to-image.jpg' // Replace with your image URL
      }, (response) => {
        if (response && !response.error_message) {
          console.log('Post was shared successfully.');
        } else {
          console.error('Error while sharing the post:', response.error_message);
        }
      });
    } else {
      console.error('Facebook SDK not initialized');
    }
  };

  return (
    <div>
      <Button onClick={handleFacebookLogin}>Login with Facebook</Button> {/* Facebook login button */}
      <Button onClick={() => setShowModal(true)}>Create Track</Button> {/* Open modal on click */}
     
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Facebook Post Tracker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Modal Content (Metrics or Post Tracking Details) */}
          <p>Post ID: {postId}</p>
          <p>Likes: {postMetrics.likes}</p>
          <p>Shares: {postMetrics.shares}</p>
          <p>Views: {postMetrics.views}</p>
          <p>Access Token: {accessToken}</p> {/* Display Access Token */}
          <p>User ID: {userId}</p> {/* Display User ID */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={shareToFeed}>
            Share to Facebook
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FacebookPostTracker;
