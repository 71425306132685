import React, { useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { useHistory } from "react-router-dom";
import { Button, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
const Youtubevideos = () => {
  const [videos, setVideos] = useState([]);
  const [channelTitle, setChannelTitle] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [channelId, setChannelId] = useState("");
  const [Playlist, setPlaylist] = useState([]);
  const [customUrl, setCustomUrl] = useState("");
  const [transactionLoaderState, setTransactionLoaderState] = useState(false);
  const history = useHistory();
  const access_token = sessionStorage.getItem("access_token");
  const upload_id = sessionStorage.getItem("upload_id");
  useEffect(() => {
    const storedData = sessionStorage.getItem("youtube_profile_data");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const profile = parsedData[0];
      setProfileUrl(profile.profile_url);
      setChannelTitle(profile.title);
      setCustomUrl(profile.custom_url);
      setChannelId(profile.channel_id);
    }
  }, []);
  const handleCardClick = (videoId) => {
    sessionStorage.setItem("selectedVideoId", videoId);
    history.push("/Go_seo/Youtube_data");
  };
  const handleplaylistCardClick = (playlistId, title) => {
    sessionStorage.setItem("playlistId", playlistId);
    sessionStorage.setItem("playlistTitle", title);
    history.push("/Go_seo/Playlist_Videos");
  };
  
  const fetchVideos = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          access_token: access_token, // Retrieve the access token
          upload_id: upload_id, // Retrieve the upload ID
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetYoutubeVideo`,
        requestOptions
      );

      const data = await res.json();
      console.log("Video fetch response:", data);
      if (data.status === "00" && data.data && data.data.items) {
        setVideos(data.data.items);
        if (data.data.items.length > 0) {
          setChannelTitle(data.data.items[0].snippet.channelTitle);
        }
        console.log("Videos set:", data.data.items);
      } else {
        console.log("No videos found or error status:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching videos:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const fetchplaylist = async (channelId) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          key: "AIzaSyDSMivxiXj653PQxHdzoJQSEYfsXobltjs",
          channelid: channelId,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetYoutubePlaylist`,
        requestOptions
      );

      const data = await res.json();
      console.log("Video fetch response:", data);
      if (data.status === "00" && data.data && data.data.items) {
        setPlaylist(data.data.items);
        console.log("Playlist set:", data.data.items);
      } else {
        console.log("No playlists found or error status:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching playlists:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    if (access_token && upload_id) {
      fetchVideos();
    }
  }, [access_token, upload_id]);
  useEffect(() => {
    if (channelId) {
      fetchplaylist(channelId);
    }
  }, [channelId]);

  return (
    <>
      <div className="row mt-3 pb-0">
        <div className="col">
          {profileUrl && (
            <img
              className="mr-3"
              src={profileUrl}
              alt="Profile"
              style={{
                width: "5rem",
                height: "5rem",
                borderRadius: "50%",
              }}
            />
          )}
          <h5 style={{ display: "inline-block", verticalAlign: "middle" }}>
            {channelTitle}{" "}
            <p
              className="mb-2 mt-2 "
              style={{ color: "green", fontWeight: "bold" }}
            >
              {customUrl}
            </p>{" "}
          </h5>
        </div>
        <div className="col d-flex justify-content-end">
          <Link to="/Go_seo/Youtube">
            <Button variant="info" size="sm">
              Back
            </Button>
          </Link>
        </div>
      </div>
      <Tab.Container defaultActiveKey="Allvideos">
        <div className="tab-buttons m-2">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                className="p-2"
                eventKey="Allvideos"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                All Videos
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="p-2"
                eventKey="Playlist"
                style={{ fontSize: "1rem", fontWeight: "500" }}
              >
                Playlist
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content className="pt-0">
          <Tab.Pane eventKey="Allvideos">
            {transactionLoaderState && <div>Loading...</div>}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {videos.map((video) => (
                <div
                  onClick={() =>
                    handleCardClick(video.snippet.resourceId.videoId)
                  }
                  key={video.id}
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "10px",
                    margin: "10px",
                    width: "calc(25% - 20px)",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    boxSizing: "border-box",
                    cursor: "pointer",
                  }}
                >
                  <h6 style={{ color: "#333", fontSize: "16px" }}>
                    {video.snippet.title}
                  </h6>
                  <p style={{ color: "#666", fontSize: "12px" }}>
                    {new Date(video.snippet.publishedAt).toLocaleString()}
                  </p>
                  <img
                    src={video.snippet.thumbnails.maxres.url}
                    alt={video.snippet.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "4px",
                    }}
                  />
                </div>
              ))}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Playlist">
            <>
              <div className="m-2">
                {Playlist.length > 0 ? (
                  Playlist.map((item) => (
                    <div
                      key={item.id}
                      className="playlist-item"
                      style={{
                        position: "relative",
                        display: "inline-block",
                        margin: "1rem",
                        width: "auto",
                      }}
                      onClick={() => handleplaylistCardClick(item.id, item.snippet.title)} 
                    >
                      <div>
                        <hr
                          className=""
                          style={{
                            width: "85%",
                            margin: "auto",
                            padding: ".1rem", color:"darkgray"
                          }}
                        />
                        <hr
                          className=""
                          style={{
                            width: "90%",
                            margin: "auto",
                            padding: ".1rem", color:"darkgray"
                          }}
                        />
                        <hr
                          className=""
                          style={{
                            width: "95%",
                            margin: "auto",
                            padding: ".1rem", color:"darkgray"
                          }}
                        />
                      </div>

                      <img
                        src={item.snippet.thumbnails.maxres.url}
                        alt={item.snippet.title}
                        style={{
                          width: "auto",
                          height: "10rem",
                          borderRadius: ".6rem",
                        }}
                      />
                      <p
                        style={{
                          position: "absolute",
                          bottom: "4.2rem",
                          right: "0.3rem",
                          backgroundColor: "#000",
                          padding: "0.2rem 0.5rem",
                          borderRadius: "0.2rem",
                          fontSize: "0.8rem",
                          zIndex: 1,
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        {item.contentDetails.itemCount} Videos
                      </p>
                      <p className="mb-0" style={{ fontWeight: "bold" }}>
                        {item.snippet.title}
                      </p>
                      <p className="mb-0" style={{ fontStyle: "italic" }}>
                        {item.status.privacyStatus.charAt(0).toUpperCase() +
                          item.status.privacyStatus.slice(1)}
                      </p>
                      <p style={{ color: "#555" }}>
                        {item.snippet.description}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No playlists available.</p>
                )}
              </div>
            </>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default Youtubevideos;
