import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Backlinkstable } from "./Table";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { AddbacklinkModal } from "./Modal";

const Backlinks = () => {
  const [siteDetails, setSiteDetails] = useState(null);
  const [backlinkurl, setbacklinkurl] = useState([]);
  const [filteredBacklinks, setFilteredBacklinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const { setTransactionLoaderState } = useContext(GlobalContext);

  useEffect(() => {
    const selectedSite = JSON.parse(sessionStorage.getItem("selectedSite"));
    if (selectedSite) {
      setSiteDetails(selectedSite);
    }
  }, []);

  const GetBackLinksUrl = async () => {
    if (!siteDetails?.id) return;
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ fkid: siteDetails?.id }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBackLinksUrl`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const backlinks = data.data.map((item) => ({
          id: item.id,
          fkid: item.fkid,
          backlink_url: item.backlink_url || "NA",
          created_on: new Date(item.craeted_on).toLocaleDateString(),
          updated_on: new Date(item.updated_on).toLocaleDateString(),
          is_indexed:
            item.is_indexed === "index" ? "Do follow index" : "No follow index",
        }));
        setbacklinkurl(backlinks);
        setFilteredBacklinks(backlinks);
      } else {
        setbacklinkurl([]);
        setFilteredBacklinks([]);
      }
      setLoading(false);
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (siteDetails?.id) {
      GetBackLinksUrl();
    }
  }, [siteDetails]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = backlinkurl.filter((item) => {
      return (
        item.backlink_url.toLowerCase().includes(query.toLowerCase()) ||
        item.is_indexed.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredBacklinks(filtered);
  };

  const handleAddbacklinkClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="m-3">
        <div className="row">
          <div className="col">
            <h5>
              Backlinks of {siteDetails ? siteDetails.siteName : "Loading..."}
            </h5>
          </div>
          <div className="col mr-3 d-flex justify-content-end">
            <Form.Control
              type="text"
              placeholder="Search..."
              style={{ width: "50%" }}
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button
              variant="info"
              className="ml-2"
              size="sm"
              onClick={handleAddbacklinkClick}
            >
              Add Backlinks
            </Button>

            <Link to="/Go_seo/Backlinks_Sites">
              <Button variant="info" className="ml-2">
                Back
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <Backlinkstable backlinkurl={filteredBacklinks} loading={loading} GetBackLinksUrl={GetBackLinksUrl}/>

      <AddbacklinkModal
        show={showModal}
        handleClose={handleCloseModal}
        GetBackLinksUrl={GetBackLinksUrl}
        siteId={siteDetails?.id}
      />
    </>
  );
};

export default Backlinks;
