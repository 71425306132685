import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Picker from "emoji-picker-react";
import swal from "sweetalert";

const Youtubedata = () => {
  const [videos, setVideos] = useState([]);
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    title: "",
    tags: "",
    description: "",
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiField, setEmojiField] = useState("");
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });

  const onEmojiClick = (emojiData) => {
    const emoji = emojiData.emoji;
    setFormData((prevData) => ({
      ...prevData,
      [emojiField]: prevData[emojiField] + emoji,
    }));
    setShowEmojiPicker(false);
  };

  const toggleEmojiPicker = (field, event) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setEmojiField(field);
    setPickerPosition({ top: buttonRect.bottom + window.scrollY, left: buttonRect.left });
    setShowEmojiPicker(!showEmojiPicker);
  };

  useEffect(() => {
    const storedVideoId = sessionStorage.getItem("selectedVideoId");
    if (storedVideoId) {
      fetchVideos(storedVideoId);
    }
  }, []);

  const fetchVideos = async (videoId) => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          key: "AIzaSyDSMivxiXj653PQxHdzoJQSEYfsXobltjs",
          video_id: videoId,
        }),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetYoutubeVideoDetails`,
        requestOptions
      );

      const data = await res.json();
      console.log("Video fetch response:", data);
      if (data.status === "00" && data.data && data.data.items) {
        setVideos(data.data.items);
        setFormData({
          title: data.data.items[0]?.snippet.title || "",
          tags: data.data.items[0]?.snippet.tags?.join(", ") || "",
          description: data.data.items[0]?.snippet.description || "",
        });
        console.log("Videos set:", data.data.items);
      } else {
        console.log("No videos found or error status:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching videos:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const storedVideoId = sessionStorage.getItem("selectedVideoId");
    const accessToken = sessionStorage.getItem("access_token");

    if (!storedVideoId || !accessToken) {
      console.error("Video ID or Access Token missing");
      return;
    }

    const tagsArray = formData.tags
      .split(/[\s,]+/)
      .map((tag) => tag.trim())
      .filter((tag) => tag);

    const requestBody = {
      access_token: accessToken,
      id: storedVideoId,
      snippet: {
        title: formData.title,
        description: formData.description,
        tags: tagsArray,
        categoryId: videos[0]?.snippet?.categoryId || "NA",
      },
    };

    try {
      setTransactionLoaderState(true);
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/UpdateYoutubeVideoSeo`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await res.json();
      console.log("Update response:", data);
      if (data.status === "00") {
        swal("Success!", data.msg, "success");
      } else {
        console.log("Error updating video SEO:", data.msg);
        swal("Error!", data.msg, "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      swal(
        "Error!",
        "An unexpected error occurred. Please try again.",
        "error"
      );
    } finally {
      setTransactionLoaderState(false);
    }
  };

  return (
    <div className="m-3">
      <Row>
        <Col>
          <h4>SEO Setting</h4>
        </Col>
        <Col md={3} className="d-flex justify-content-end">
          <Link to="/Go_seo/Youtube_Videos">
            <Button size="sm" variant="info">
              Back
            </Button>
          </Link>
        </Col>
      </Row>
      {videos.length > 0 ? (
        videos.map((video) => (
          <div key={video.id} style={{ marginBottom: "20px" }}>
            <Row className="align-items-center">
              <Col md={2} style={{ borderRight: "1px solid lightgrey" }}>
                <img
                  src={video.snippet.thumbnails.high.url}
                  alt={video.snippet.title}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </Col>
              <Col>
                {videos.length > 0 && (
                  <h4>{videos[0].snippet.title || "NA"}</h4>
                )}
                <p>
                  <strong>
                    <span role="img" aria-label="eye">
                      👁️
                    </span>{" "}
                    {video.statistics.viewCount} Views | &nbsp;
                    <span role="img" aria-label="thumbs up">
                      👍
                    </span>{" "}
                    {video.statistics.likeCount} Likes | &nbsp;
                    <span role="img" aria-label="speech balloon">
                      💬
                    </span>{" "}
                    {video.statistics.commentCount} Comments
                  </strong>
                </p>
              </Col>
            </Row>
          </div>
        ))
      ) : (
        <p>No videos found.</p>
      )}
      <Form onSubmit={handleSubmit} className="mt-4 col-9">
        <Form.Group controlId="formTitle">
          <Form.Label>Title</Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Enter title"
            />
            <Button
              variant="light"
              size="sm"
              onClick={(e) => toggleEmojiPicker("title", e)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              😀
            </Button>
          </div>
        </Form.Group>
        <Form.Group controlId="formTags">
          <Form.Label>Tags</Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              type="text"
              name="tags"
              value={formData.tags}
              onChange={handleChange}
              placeholder="Enter tags separated by commas"
            />
            <Button
              variant="light"
              size="sm"
              onClick={(e) => toggleEmojiPicker("tags", e)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              😀
            </Button>
          </div>
        </Form.Group>
        <Form.Group controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <div style={{ position: "relative" }}>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter description"
            />
            <Button
              variant="light"
              size="sm"
              onClick={(e) => toggleEmojiPicker("description", e)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              😀
            </Button>
          </div>
        </Form.Group>
        {showEmojiPicker && (
          <Picker
            onEmojiClick={onEmojiClick}
            pickerStyle={{
              position: "absolute",
              zIndex: 1000,
              top: pickerPosition.top,
              left: pickerPosition.left,
            }}
          />
        )}

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Youtubedata;
