import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { AddbacklinksSiteModal } from "./Modal";
import { Backlinkssitetable } from "./Table";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import { GlobalContext } from "../Context/GlobalState";
import Select from "react-select";
const Backlinksite = () => {
  const url = sessionStorage.getItem("selectedURLLabel");
  const [showModal, setShowModal] = useState(false);
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);


  const { setTransactionLoaderState } = useContext(GlobalContext);

  const GetBackLinksCredentials = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetBackLinksCredentials`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        const sitesData = data.data.map((item) => ({
          id: item.id,
          siteName: item.website_url,
          email: item.email,
          username: item.username,
          password: item.password,
          domainAuthority: item.domain_authority,
          pageAuthority: item.page_authority,
          createdon: new Date(item.created_on).toLocaleDateString(),
          updatedon: new Date(item.updated_on).toLocaleDateString(),
          count: item.total_url,
          userid: item.userid,
        }));
        setSites(sitesData);
        setFilteredSites(sitesData);
      } else {
        setSites([]);
        setFilteredSites([]);
      }
      setLoading(false);
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
      setLoading(false);
    }
  };
  const GetGoseoUsers = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/GetGoseoUsers`,
        requestOptions
      );
      const data = await response.json();
      if (data.status === "0") {
        const userOptions = data.data.map((user) => ({
          value: user.id,
          label: user.user_name,
        }));
        setUsers(userOptions);
      }
    } catch (error) {
      console.log("Error fetching users:", error);
    }
  };

  useEffect(() => {
    GetGoseoUsers();
    const storedSites = sessionStorage.getItem("backlinksSites");
    if (storedSites) {
      const parsedSites = JSON.parse(storedSites);
      setSites(parsedSites);
      setFilteredSites(parsedSites);
      setLoading(false);
    } else {
      GetBackLinksCredentials();
    }
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = sites.filter((site) => {
      return (
        site.siteName.toLowerCase().includes(query.toLowerCase()) ||
        site.email.toLowerCase().includes(query.toLowerCase()) ||
        site.username.toLowerCase().includes(query.toLowerCase()) ||
        site.password.toLowerCase().includes(query.toLowerCase()) ||
        site.domainAuthority.toString().includes(query) ||
        site.pageAuthority.toString().includes(query)
      );
    });
    setFilteredSites(filtered);
  };

  const handleAddSiteClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
    if (selectedOption) {
      const filteredByUser = sites.filter(
        (site) => site.userid === selectedOption.value
      );
      setFilteredSites(filteredByUser);
    } else {
      setFilteredSites(sites);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="row">
          <div className="col">
            <h5>
              Backlinks Sites of <u>{url}</u>
            </h5>
          </div>
          <div className="col mr-3 d-flex justify-content-end">
          <Select
              options={users}
              value={selectedUser}
              onChange={handleUserChange}
              placeholder="Select User"
              className="ml-2"
              styles={{ container: (provided) => ({ ...provided, width: "30%" }) }}
            />
            <Form.Control
              type="text"
              placeholder="Search..."
              style={{ width: "50%" }}
              value={searchQuery}
                 className="ml-2"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Button
              variant="info"
              className="ml-2"
              size="sm"
              onClick={handleAddSiteClick}
            >
              Add Site
            </Button>
          </div>
        </div>
      </div>

      <Backlinkssitetable sites={filteredSites} loading={loading} GetBackLinksCredentials={GetBackLinksCredentials}/>

      <AddbacklinksSiteModal
        show={showModal}
        handleClose={handleCloseModal}
        GetBackLinksCredentials={GetBackLinksCredentials}
      />
    </>
  );
};

export default Backlinksite;
