import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { MdNoPhotography } from "react-icons/md";
import { Modal, Button, ListGroup, Spinner, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
const Facebook = () => {
  const [campaign, setCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [userID, setUserID] = useState("1");
  const BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const handlePreview = (template) => {
    setSelectedTemplate(template);
    setShowModal(true);
  };
  const handleClose = () => setShowModal(false);
  const getCampaign = async () => {
    try {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
      const raw = JSON.stringify({
        userid: userID,
        campaignName: "string",
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `https://api.anlook.com/api/Campaign/getCampaigns`,
        requestOptions
      );
      const data = await res.json();
      if (data?.responseCode === "00") {
        setCampaign(data?.data);
      } else {
        console.log(data?.responseMessage || "Failed to fetch campaigns");
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCampaign();
  }, []);
  const filteredTemplates = campaign.filter((template) => {
    return (
      (selectedType === "All" || template.type === selectedType) &&
      (selectedCategory === "All" || template.category === selectedCategory)
    );
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    const userSession = sessionStorage.getItem("userInfo");
    if (userSession) {
      setIsLoggedIn(true);
      setUserInfo(JSON.parse(userSession));
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  const handleFacebookLogin = () => {
    window.location.href = "https://goseo.in/facebook/facebook_oauth_goseo.php";
  };
  const handleLogout = () => {
    sessionStorage.removeItem("userInfo");
    setIsLoggedIn(false);
    setUserInfo(null);
  };
  const handleShare = (template) => {
    console.log("Share clicked for", template.campaign_name);
  };
  const [userData, setUserData] = useState(null);
  const [url, setUrl] = useState("");
  const fetchData = async () => {
    try {
      const requestBody = { userid: url };
      console.log("Request Body:", requestBody);
      const response = await axios.post(
        `https://api.goseo.in/api/Facebook/GetFacebookUserDetails`,
        requestBody
      );
      console.log("API Response:", response.data);
      if (response.data) {
        const { name, id: userId, email } = response.data;
        const userDetails = { name, id: userId, email };
        console.log("Setting userData:", userDetails);
        setIsLoggedIn(true);
        setUserData(userDetails);
        sessionStorage.setItem("userdata", JSON.stringify(userDetails));
        console.log("User data stored in sessionStorage");
      } else {
        console.error("Unexpected response structure:", response.data);
      }
    } catch (error) {
      console.error(
        "Error fetching user data:",
        error.response ? error.response.data : error.message
      );
    }
  };
  useEffect(() => {
    const getUserIdFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get("userid");
      setUrl(userId);
    };
    getUserIdFromUrl();
  }, []);
  useEffect(() => {
    if (url) {
      fetchData();
    }
  }, [url]);
  const [isSessionStorageUsed, setIsSessionStorageUsed] = useState(false);
  useEffect(() => {
    const storedUserData = sessionStorage.getItem("userdata");
    if (storedUserData) {
      console.log("Session storage contains:", JSON.parse(storedUserData));
      setUserData(JSON.parse(storedUserData));
      setIsSessionStorageUsed(true);
      setIsLoggedIn(true);
    } else {
      console.log("No userdata found in session storage.");
      setIsSessionStorageUsed(false);
    }
  }, []);
  const [showPageSelection, setShowPageSelection] = useState(false);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isPostDisabled, setIsPostDisabled] = useState(true);
  const [loadingPages, setLoadingPages] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const [error, setError] = useState(null);
  const handleModalClose = () => {
    setShowPageSelection(false);
    setPages([]);
    setSelectedPage(null);
    setIsPostDisabled(true);
    setLoadingPages(false);
    setErrorMessage("");
    handleClose();
  };
  const handleSelectPageClick = () => {
    setLoadingPages(true);
    axios
      .post("https://api.goseo.in/api/Facebook/GetFacebookPages", {
        userid: "a1001",
      })
      .then((response) => {
        const fetchedPages = response.data.data;
        setPages(fetchedPages);
        setShowPageSelection(true);
        setLoadingPages(false);
      })
      .catch((error) => {
        console.error("Error fetching pages:", error);
        setErrorMessage("Failed to fetch pages. Please try again.");
        setLoadingPages(false);
      });
  };

  const handlePageSelect = (page) => {
    setSelectedPage(page);
    setIsPostDisabled(false);
    console.log("Selected Page Access Token:", page.access_token);
  };

  const handlePostNow = async () => {
    if (!selectedPage) {
      alert("Please select a page.");
      return;
    }
    setIsPosting(true);
    setError(null);

    const payload = {
      pageid: selectedPage.id,
      accessToken: selectedPage.access_token,
      message: selectedTemplate.bodyParams
        ? selectedTemplate.bodyParams[0]
        : "Default message",
      link: "",
      featured_image: selectedTemplate.headerParams
        ? selectedTemplate.headerParams[0]
        : "",
      tittle: selectedTemplate.campaign_name || "Default Title",
      description: "Your custom description here",
    };

    try {
      const response = await axios.post(
        "https://api.goseo.in/api/Facebook/PostFacebookFeed",
        payload
      );

      if (response.status === 200) {
        toast.success("Post successfully published!");

        handleModalClose();
      } else {
        setError("Failed to post the feed. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while posting. Please try again.");
    } finally {
      setIsPosting(false);
    }
  };

  return (
    <div >
      {/* <Link to="/Go_seo/Facebookcards">
      Cards
      </Link> */}
      <ToastContainer />

      <Modal show={showModal} onHide={handleModalClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Preview</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loadingPages && (
            <div className="spinner-container">
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
              <p>Fetching pages...</p>
            </div>
          )}

          {/* Error Message */}
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage("")}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}

          {selectedTemplate && !showPageSelection && !loadingPages && (
            <>
              <div className="preview-container">
                <div className="profile-info">
                {selectedTemplate.headerParams &&
                selectedTemplate.headerParams.length > 0 &&
                /\.(png|jpe?g)$/i.test(selectedTemplate.headerParams[0]) ? (
                  <img
                    src={selectedTemplate.headerParams[0]}
                    alt={selectedTemplate.campaign_name}
                    className="preview-image"
                  />
                ) : (
                  <p className="no-image-text">
                    Image not included in this campaign
                  </p>
                )}
                  <div>
                    <h5 className="profile-name">
                      {selectedTemplate.campaign_name || "User Name"}
                    </h5>
                  </div>
                </div>

                {/* Description */}
                <p className="description">
                  {selectedTemplate.bodyParams
                    ? selectedTemplate.bodyParams[0]
                    : "We get it, sometimes things don’t go as planned. But don’t worry, we’re here to make things right!"}
                </p>

                {/* Image Preview */}
             
              </div>
            </>
          )}

          {showPageSelection && !loadingPages && (
            <div>
              <h5>Select a Page to Post</h5>
              <ListGroup>
                {pages.map((page) => (
                  <ListGroup.Item
                    key={page.id}
                    active={selectedPage && selectedPage.id === page.id}
                    onClick={() => handlePageSelect(page)}
                    className="page-item d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        checked={selectedPage && selectedPage.id === page.id}
                        onChange={() => handlePageSelect(page)}
                        className="feedpost-radio"
                        name="pageSelection"
                      />
                      <div className="page-details ms-2">
                        {" "}
                        <h6 className="page-name">{page.name}</h6>
                        <p className="page-category">
                          {page.category || "Unknown Category"}
                        </p>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cancel
          </Button>

          {!showPageSelection ? (
            <Button
              variant="primary"
              onClick={handleSelectPageClick}
              disabled={loadingPages}
            >
              Select Page
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={handlePostNow}
              disabled={isPostDisabled || loadingPages}
            >
              Post Now
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <div
        style={{
          backgroundColor: "#ddd",
          color: "#000",
        }}
      >
        {!isLoggedIn && (
          <>
            <div
              className=" d-flex align-items-center justify-content-between p-3 border border-success rounded-4 shadow-sm mb-3"
              style={{ backgroundColor: "#A3C1F5" }}
            >
              <div className="d-flex align-items-center">
                <img
                  className="card-img-top"
                  src={require("../../assets/images/seoimages/facebook.png")}
                  style={{ width: "4vw" }}
                  alt="Facebook Icon"
                />
                <div className="ml-2 d-flex flex-column">
                  <h6 style={{ color: "#161D6F" }}>
                    {" "}
                    To continue with these actions, please connect your Facebook
                    page.
                  </h6>
                </div>
              </div>
              <div>
                <Button
                  size="sm"
                  variant="info"
                  onClick={handleFacebookLogin}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#295F98",
                    color: "#fff",
                    padding: "8px 15px",
                  }}
                >
                  Login
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="custom-scroll-hide" style={{ height: "80vh" }} >
        {isLoggedIn && (
          <div
            className=" d-flex align-items-center justify-content-between p-3 border border-success rounded-4 shadow-sm mb-3"
            style={{ backgroundColor: "#A3C1F5" }}
          >
            <div className="d-flex align-items-center">
              <img
                className="card-img-top"
                src={require("../../assets/images/seoimages/facebook.png")}
                style={{ width: "4vw" }}
                alt="Facebook Icon"
              />
              <div className="ml-3 d-flex flex-column">
                {userData ? (
                  <>
                    {userData.name && (
                      <span
                        style={{ color: "#161D6F" }}
                        className="fs-5 fw-bold  py-1"
                      >
                        <b> {userData.name}</b>
                      </span>
                    )}
                    {userData.id && (
                      <span style={{ color: "#161D6F" }} className=" py-1">
                        <strong>ID:</strong> <b>{userData.id}</b>
                      </span>
                    )}
                    {userData.email && (
                      <span style={{ color: "#161D6F" }} className=" py-1">
                        <strong>Email:</strong> <b>{userData.email}</b>
                      </span>
                    )}
                  </>
                ) : (
                  <span className="text-secondary">Loading user data...</span>
                )}
              </div>
            </div>
            <div>
              <Button
                size="sm"
                style={{
                  padding: "8px 15px",
                  backgroundColor: "#295F98",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Connected
              </Button>
            </div>
          </div>
        )}

        <div
          className="px-3"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>Facebook Feed</h5>
        </div>
        <div className="">
          {loading ? (
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Loading...
            </p>
          ) : (
            <>
              <div className="row">
                {filteredTemplates.map((template) => (
                  <div
                    key={template.id}
                    className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4"
                  >
                    <div style={cardStyle} className="template-card">
                      <div className="card-content">
                        {template.headerParams &&
                        template.headerParams.length > 0 ? (
                          /\.(png|jpe?g)$/i.test(template.headerParams[0]) ? (
                            <img
                              src={template.headerParams[0]}
                              alt={template.campaign_name}
                              style={{
                                width: "100%",
                                height: "180px",
                                objectFit: "cover",
                                borderRadius: "8px 8px 0 0",
                              }}
                            />
                          ) : (
                            <div style={fallbackTextStyle}>
                              <MdNoPhotography style={{ fontSize: "2rem" }} />
                            </div>
                          )
                        ) : (
                          <div style={fallbackTextStyle}>
                            <MdNoPhotography style={{ fontSize: "2rem" }} />
                          </div>
                        )}
                        <div style={{ padding: "15px" }}>
                          <h5
                            style={{
                              fontSize: "16px",
                              margin: "0 0 10px",
                              fontWeight: "bold",
                            }}
                          >
                            {template.campaign_name}
                          </h5>
                        </div>
                      </div>
                      <div className="feed-button-container">
                        {isLoggedIn ? (
                          <>
                            <button
                              className="blue-button px-5 py-2"
                              size="sm"
                              onClick={() => handlePreview(template)}
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#FFE31A",
                                color: "#295F98",
                                borderRadius: ".4rem",
                              }}
                            >
                              Preview
                            </button>
                            {/* <button
                              className="blue-button mt-2 px-5 py-2"
                              onClick={() => handleShare(template)}
                            >
                              Share
                            </button> */}
                          </>
                        ) : (
                          <Button
                            className="p-2"
                            size="sm"
                            onClick={handleFacebookLogin}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#FFE31A",
                              color: "#295F98",
                              borderRadius: ".4rem",
                            }}
                          >
                            <b>Login</b>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const cardStyle = {
  backgroundColor: "#fff",
  borderRadius: "8px",
  border: "1px solid #ccc",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
};
const fallbackTextStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "180px",
  border: "1px dashed #ccc",
  borderRadius: "8px",
  backgroundColor: "#f8f9fa",
  color: "#666",
  fontSize: "16px",
  fontStyle: "italic",
  textAlign: "center",
};

export default Facebook;
