import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Button, Card, Form, Modal, Row, Spinner } from "react-bootstrap";
import ReactJson from "react-json-view";
import { GlobalContext } from "../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../Utils/Constant";
import swal from "sweetalert";
import { FaPlus, FaTrash } from "react-icons/fa";

export const Projectmodal = ({
  modalIsOpen,
  handleClose,
  urlOptions,
  selectedURL,
  handleSelectChange,
}) => {
  return (
    <>
      <div>
        <Modal show={modalIsOpen} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Select Project URL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span style={{ color: "#0F67B1" }}>Select Project :</span>
              <Select
                options={urlOptions}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select URL"
                value={selectedURL}
                onChange={handleSelectChange}
                menuPlacement="auto"
                menuPosition="fixed"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    borderColor: "#ccc",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    zIndex: 1050,
                  }),
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export const Jsonmodal = ({
  showModal,
  handleCloseModal,
  loadingModal,
  selectedURL,
  jsonData,
  validateJson,
  handleSubmit,
}) => {
  const preventCopyPaste = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div>
        <Modal show={showModal} onHide={handleCloseModal} className="jsonmodal">
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedURL ? `JSON for ${selectedURL.label}` : "JSON Data"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{ height: "70vh", overflow: "auto", userSelect: "none" }}
              onCopy={preventCopyPaste}
              onPaste={preventCopyPaste}
            >
              {loadingModal ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <ReactJson src={jsonData} />
              )}
            </div>
            <Button
              className="mt-4 mr-2"
              variant="info"
              size="sm"
              onClick={validateJson}
            >
              Validate JOSN
            </Button>

            <Button
              className="mt-4"
              variant="info"
              size="sm"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export const AddbacklinksSiteModal = ({
  show,
  handleClose,
  GetBackLinksCredentials,
}) => {
  const { user } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    websiteurl: "",
    email: "",
    password: "",
    username: "",
    domain_authority: "",
    page_authority: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      webisteurl: formData.websiteurl,
      email: formData.email,
      password: formData.password,
      username: formData.username,
      domain_authoriy: formData.domain_authority,
      page_authoriy: formData.page_authority,
      userId: user.user_id,
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/InsertBacklinkCredentials`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.result[0].message, "success");
        handleClose();
        GetBackLinksCredentials();
      } else {
        swal("Error", data.msg, "error");
        GetBackLinksCredentials();
      }

      console.log("Form submission completed.");
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Site</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="websiteurl">
              <Form.Label>Website URL</Form.Label>
              <Form.Control
                type="url"
                name="websiteurl"
                value={formData.websiteurl}
                placeholder="Enter website URL"
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                placeholder="Enter contact email"
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                placeholder="Enter login username"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="col" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                name="password"
                value={formData.password}
                placeholder="Enter login password"
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>

          <div className="row m-0 p-0">
            <Form.Group className="col" controlId="domain_authority">
              <Form.Label>Domain Authority</Form.Label>
              <Form.Control
                type="number"
                name="domain_authority"
                value={formData.domain_authority}
                placeholder="Enter domain authority"
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="col" controlId="page_authority">
              <Form.Label>Page Authority</Form.Label>
              <Form.Control
                type="number"
                name="page_authority"
                value={formData.page_authority}
                placeholder="Enter page authority"
                onChange={handleInputChange}
              />
            </Form.Group>
          </div>
        </Form>

        <div className="row m-0 p-0 mx-3">
          <Button size="sm" variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const EditSiteModal = ({
  showModal,
  handleModalClose,
  handleFormSubmit,
  formData,
  setFormData,
}) => {
  useEffect(() => {
    if (!showModal) {
      setFormData({
        weburl: "",
        email: "",
        password: "",
        username: "",
        da: "",
        pa: "",
      });
    }
  }, [showModal, setFormData]);

  return (
    <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Site Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Row className="p-0 m-0">
            {" "}
            <Form.Group className="col p-1" controlId="weburl">
              <Form.Label>Web URL</Form.Label>
              <Form.Control
                type="text"
                value={formData.weburl}
                onChange={(e) =>
                  setFormData({ ...formData, weburl: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="col p-1" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="p-0 m-0">
            <Form.Group className="col p-1" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="col p-1" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={formData.username}
                onChange={(e) =>
                  setFormData({ ...formData, username: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Row className="p-0 m-0">
            <Form.Group className="col p-1" controlId="da">
              <Form.Label>Domain Authority</Form.Label>
              <Form.Control
                type="text"
                value={formData.da}
                onChange={(e) =>
                  setFormData({ ...formData, da: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="col p-1" controlId="pa">
              <Form.Label>Page Authority</Form.Label>
              <Form.Control
                type="text"
                value={formData.pa}
                onChange={(e) =>
                  setFormData({ ...formData, pa: e.target.value })
                }
              />
            </Form.Group>
          </Row>
          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const AddbacklinkModal = ({
  show,
  handleClose,
  GetBackLinksUrl,
  siteId,
}) => {
  const [backlinks, setBacklinks] = useState([
    { backlink_url: "", indexType: "" },
  ]);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newBacklinks = [...backlinks];
    newBacklinks[index][name] = value;
    setBacklinks(newBacklinks);
  };

  const handleAddBacklink = () => {
    setBacklinks([...backlinks, { backlink_url: "", indexType: "" }]);
  };

  const handleRemoveBacklink = (index) => {
    const newBacklinks = backlinks.filter((_, idx) => idx !== index);
    setBacklinks(newBacklinks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      requests: backlinks.map((link) => ({
        fkid: siteId,
        backlink_url: link.backlink_url,
        is_indexed: link.indexType || "N",
      })),
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/InsertBacklinksUrl`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal("Success", data.msg, "success");
        handleClose();
        GetBackLinksUrl();
      } else {
        swal("Error", data.msg, "error");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New Backlinks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {backlinks.map((backlink, index) => (
            <div key={index} className="row m-0 p-0 align-items-center">
              <Form.Group className="col-5" controlId={`backlink_url_${index}`}>
                <Form.Label>Backlink URL</Form.Label>
                <Form.Control
                  type="url"
                  name="backlink_url"
                  value={backlink.backlink_url}
                  placeholder="Enter Backlink URL"
                  onChange={(e) => handleInputChange(index, e)}
                  required
                />
              </Form.Group>

              <Form.Group className="col-5" controlId={`indexType_${index}`}>
                <div>
                  <Form.Check
                    inline
                    label="Do follow index"
                    type="checkbox"
                    name="indexType"
                    checked={backlink.indexType === "index"}
                    onChange={(e) => {
                      const newIndexType = e.target.checked ? "index" : "";
                      const newBacklinks = [...backlinks];
                      newBacklinks[index].indexType = newIndexType;
                      setBacklinks(newBacklinks);
                    }}
                  />
                  <Form.Check
                    inline
                    label="No follow index"
                    type="checkbox"
                    name="indexType"
                    checked={backlink.indexType === "noindex"}
                    onChange={(e) => {
                      const newIndexType = e.target.checked ? "noindex" : "";
                      const newBacklinks = [...backlinks];
                      newBacklinks[index].indexType = newIndexType;
                      setBacklinks(newBacklinks);
                    }}
                  />
                </div>
              </Form.Group>

              <div className="col-2 d-flex align-items-center justify-content-center">
                {index === backlinks.length - 1 ? (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={handleAddBacklink}
                  >
                    <FaPlus />
                  </Button>
                ) : (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleRemoveBacklink(index)}
                  >
                    <FaTrash />
                  </Button>
                )}
              </div>
            </div>
          ))}

          <div className="row m-0 p-0 mt-3 mx-3">
            <Button size="sm" variant="primary" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const EditBacklinkModal = ({
  show,
  handleClose,
  backlink,
  handleUpdate,
  GetBackLinksUrl,
}) => {
  const [formData, setFormData] = useState({
    backlink_url: "",
    indexType: "",
  });

  useEffect(() => {
    if (backlink) {
      console.log(backlink);
      const newIndexType =
        backlink.is_indexed === "Do follow index" ? "index" : "noindex";
      setFormData({
        backlink_url: backlink.backlink_url || "",
        indexType: newIndexType,
      });
      console.log("Updated indexType:", newIndexType);
    }
  }, [backlink]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      id: backlink.id,
      fkid: backlink.fkid,
      url: formData.backlink_url,
      isindexed: formData.indexType,
    };

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      };

      console.log("Sending data to the server");
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Seo/EditBacklinkURL`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        swal(
          "Success",
          data.result[0].message || "Url Edited Successfully",
          "success"
        );

        handleClose();
        GetBackLinksUrl();
      } else {
        swal("Error", data.msg || "Failed to update backlink", "error");
      }
    } catch (error) {
      console.error("Error updating backlink:", error);
      swal("Error", "An error occurred while updating the backlink", "error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Backlink</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="backlink_url">
            <Form.Label>Backlink URL</Form.Label>
            <Form.Control
              type="url"
              name="backlink_url"
              value={formData.backlink_url}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="indexType">
            <Form.Label>Index Type</Form.Label>
            <div>
              <Form.Check
                inline
                label="Do follow index"
                type="radio"
                name="indexType"
                value="index"
                checked={formData.indexType === "index"}
                onChange={handleInputChange}
              />
              <Form.Check
                inline
                label="No follow index"
                type="radio"
                name="indexType"
                value="noindex"
                checked={formData.indexType === "noindex"}
                onChange={handleInputChange}
              />
            </div>
          </Form.Group>

          <Button variant="primary" type="submit">
            Save Changes
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
